<template>
  <ToolsFormTwisting />
</template>

<script>
import ToolsFormTwisting from '../../components/tools/ToolsFormTwisting.vue';

export default {
  components: {
    ToolsFormTwisting,
  },
  data() {
    return {};
  },
};
</script>

<style scoped lang="scss">

</style>
