import OutputHelper from './OutputHelper';

export default {
  // mittige Einzellast
  getCenteredSingleLoad(fMax, l) {
    const fMaxCasted = OutputHelper.convertToNumber(fMax);
    const lCasted = OutputHelper.convertToNumber(l);
    const alphaTotal = (3 * fMaxCasted / lCasted) * 1000;
    return {
      alphaA: alphaTotal,
      alphaB: alphaTotal,
    };
  },
  // Gleichstreckenlast
  getEqualDistanceLoad(fMax, l) {
    const fMaxCasted = OutputHelper.convertToNumber(fMax);
    const lCasted = OutputHelper.convertToNumber(l);
    const alphaTotal = (3.2 * fMaxCasted / lCasted) * 1000;
    return {
      alphaA: alphaTotal,
      alphaB: alphaTotal,
    };
  },
  // außermittige Einzellast
  getEccentricallySingleLoad(fMax, l, a, b) {
    const fMaxCasted = OutputHelper.convertToNumber(fMax);
    const lCasted = OutputHelper.convertToNumber(l);
    const aCasted = OutputHelper.convertToNumber(a);
    const bCasted = OutputHelper.convertToNumber(b);
    const alphaA = 3 * Math.sqrt(3)
      * aCasted
      * (lCasted + bCasted)
      * fMaxCasted / (2 * (((lCasted ** 2) - (bCasted ** 2)) ** (3 / 2)));
    const alphaB = 3 * Math.sqrt(3)
      * aCasted
      * (lCasted + aCasted)
      * fMaxCasted / (2 * (((lCasted ** 2) - (bCasted ** 2)) ** (3 / 2)));
    return {
      alphaA: alphaA * 1000,
      alphaB: alphaB * 1000,
    };
  },
  // zweisymmetrische Lasten
  getTwoSymmetricLoads(fMax, l, a) {
    const fMaxCasted = OutputHelper.convertToNumber(fMax);
    const lCasted = OutputHelper.convertToNumber(l);
    const aCasted = OutputHelper.convertToNumber(a);
    const alphaTotal = 12 * fMaxCasted * (lCasted - aCasted) / (3 * (lCasted ** 2) - 4 * (a ** 2));
    return {
      alphaA: alphaTotal * 1000,
      alphaB: alphaTotal * 1000,
    };
  },
  // Dreieckslast
  getDeltaLoad(fMax, l) {
    const fMaxCasted = OutputHelper.convertToNumber(fMax);
    const lCasted = OutputHelper.convertToNumber(l);
    const alphaA = 3 * fMaxCasted / lCasted;
    const alphaB = 3.42 * fMaxCasted / lCasted;
    return {
      alphaA: alphaA * 1000,
      alphaB: alphaB * 1000,
    };
  },
  // Drehmoment
  getTorque(fMax, l) {
    const fMaxCasted = OutputHelper.convertToNumber(fMax);
    const lCasted = OutputHelper.convertToNumber(l);
    const alphaA = 3 * Math.sqrt(3) * fMaxCasted / lCasted;
    const alphaB = 3 * Math.sqrt(3) * fMaxCasted / (2 * lCasted);
    return {
      alphaA: alphaA * 1000,
      alphaB: alphaB * 1000,
    };
  },
};
