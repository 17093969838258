<template>
  <div class="row">
    <div class="col-md-6">
      <div class="panel has-bg">
        <h3>
          {{ $t('twisting.headlineTwisting') }}
          <b-button
            id="popover-twisting"
            variant="default"
          >
            <font-awesome-icon icon="question-circle" />
          </b-button>
          <b-popover
            target="popover-twisting"
            triggers="hover"
            placement="top"
          >
            <p>{{ $t('twisting.twistingTooltip') }}</p>
            <p v-html="$t('general.tooltipAdditionalInfo')"></p>
          </b-popover>
        </h3>
        <div class="row">
          <!-- Lastart -->
          <div class="col-lg-6">
            <b-form-group label-for="loadType">
              <template slot="label">
                {{ $t('twisting.loadType') }}
              </template>
              <b-input-group>
                <b-form-select
                  id="supportingBearingType"
                  v-model="loadType"
                  :options="loadTypeOptions"
                >
                </b-form-select>
              </b-input-group>
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <!-- Maximale Durchbiegung -->
            <b-form-group label-for="maxDeflection">
              <template slot="label">
                {{ $t('twisting.maxDeflection') }}
                <span class="text-muted">f<sub>max</sub></span>
              </template>
              <b-input-group append="cm">
                <FormInputDecimal
                  id="maxDeflection"
                  v-model="maxDeflection"
                />
              </b-input-group>
            </b-form-group>
          </div>
          <div class="col-lg-6">
            <!-- Systemlänge -->
            <b-form-group label-for="systemLength">
              <template slot="label">
                {{ $t('twisting.systemLength') }}
                <span class="text-muted">L</span>
              </template>
              <b-input-group append="cm">
                <FormInputDecimal
                  id="systemLength"
                  v-model="systemLength"
                />
              </b-input-group>
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div
            v-show="showDistanceBearingLoadA"
            class="col-lg-6"
          >
            <!-- Abstand Auflager-Last A -->
            <b-form-group label-for="distanceBearingLoadA">
              <template slot="label">
                {{ $t('twisting.distanceBearingLoad') }}
                <span class="text-muted">a</span>
              </template>
              <b-input-group append="cm">
                <FormInputDecimal
                  id="distanceBearingLoadA"
                  v-model="distanceBearingLoadA"
                />
              </b-input-group>
            </b-form-group>
          </div>
          <div
            v-show="showDistanceBearingLoadB"
            class="col-lg-6"
          >
            <!-- Abstand Auflager-Last B -->
            <b-form-group label-for="distanceBearingLoadB">
              <template slot="label">
                {{ $t('twisting.distanceBearingLoad') }}
                <span class="text-muted">b</span>
              </template>
              <b-input-group append="cm">
                <FormInputDecimal
                  id="distanceBearingLoadB"
                  v-model="distanceBearingLoadB"
                />
              </b-input-group>
            </b-form-group>
          </div>
        </div>
      </div>
      <div class="panel has-bg">
        <h3>{{ $t('twisting.headlineIllustrationLoadType') }}</h3>
        <div class="load-type">
          <img
            v-if="loadType === 'centeredSingleLoad'"
            src="../../assets/img/abbildungen/lastart/mittige_einzellast.jpg"
            alt=""
          />
          <img
            v-if="loadType === 'equalDistanceLoad'"
            src="../../assets/img/abbildungen/lastart/gleichstreckenlast.jpg"
            alt=""
          />
          <img
            v-if="loadType === 'twoSymmetricLoads'"
            src="../../assets/img/abbildungen/lastart/zwei_symetrische_lasten.jpg"
            alt=""
          />
          <img
            v-if="loadType === 'eccentricallySingleLoad'"
            src="../../assets/img/abbildungen/lastart/aussermittige_einzellast.jpg"
            alt=""
          />
          <img
            v-if="loadType === 'deltaLoad'"
            src="../../assets/img/abbildungen/lastart/dreieckslast.jpg"
            alt=""
          />
          <img
            v-if="loadType === 'torque'"
            src="../../assets/img/abbildungen/lastart/drehmoment.jpg"
            alt=""
          />
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="panel">
        <h3>{{ $t('bearings.headlineKeyFigures') }}</h3>
        <div class="table-responsive">
          <table class="table table-striped">
            <tbody>
              <tr>
                <td>
                  {{ $t('twisting.bearingTwistingAngleA') }}
                  <span class="text-muted">&alpha;<sub>a</sub></span>
                </td>
                <td class="text-right">
                  {{ twistingAngle.alphaA | numeralFormat('0.00') }} mrad
                </td>
              </tr>
              <tr>
                <td>
                  {{ $t('twisting.bearingTwistingAngleB') }}
                  <span class="text-muted">&alpha;<sub>b</sub></span>
                </td>
                <td class="text-right">
                  {{ twistingAngle.alphaB | numeralFormat('0.00') }} mrad
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="panel has-bg">
        <h3>{{ $t('twisting.headlineIllustration') }}</h3>
        <img
          src="../../assets/img/abbildungen/bauteilverdrehung.jpg"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
import FormInputDecimal from '../app/form/FormInputDecimal.vue';
import CalcToolsTwistingHelper from '../../helpers/CalcToolsTwistingHelper';

export default {
  components: {
    FormInputDecimal,
  },
  data() {
    return {
      loadType: 'centeredSingleLoad',
      loadTypeOptions: Object.values(this.$dataOptionsHelper.getLoadTypeOptions(this.$i18n)),
      maxDeflection: 10,
      systemLength: 1000,
      distanceBearingLoadA: 5,
      distanceBearingLoadB: 6,
    };
  },
  computed: {
    showDistanceBearingLoadA() {
      let show = false;
      if (this.loadType === 'eccentricallySingleLoad' || this.loadType === 'twoSymmetricLoads') {
        show = true;
      }
      return show;
    },
    showDistanceBearingLoadB() {
      let show = false;
      if (this.loadType === 'eccentricallySingleLoad') {
        show = true;
      }
      return show;
    },
    twistingAngle() {
      let bearingTwistingAngle;
      if (this.loadType === 'centeredSingleLoad') {
        bearingTwistingAngle = CalcToolsTwistingHelper.getCenteredSingleLoad(
          this.maxDeflection,
          this.systemLength,
        );
      } else if (this.loadType === 'equalDistanceLoad') {
        bearingTwistingAngle = CalcToolsTwistingHelper.getEqualDistanceLoad(
          this.maxDeflection,
          this.systemLength,
        );
      } else if (this.loadType === 'twoSymmetricLoads') {
        bearingTwistingAngle = CalcToolsTwistingHelper.getTwoSymmetricLoads(
          this.maxDeflection,
          this.systemLength,
          this.distanceBearingLoadA,
        );
      } else if (this.loadType === 'eccentricallySingleLoad') {
        bearingTwistingAngle = CalcToolsTwistingHelper.getEccentricallySingleLoad(
          this.maxDeflection,
          this.systemLength,
          this.distanceBearingLoadA,
          this.distanceBearingLoadB,
        );
      } else if (this.loadType === 'deltaLoad') {
        bearingTwistingAngle = CalcToolsTwistingHelper.getDeltaLoad(
          this.maxDeflection,
          this.systemLength,
        );
      } else if (this.loadType === 'torque') {
        bearingTwistingAngle = CalcToolsTwistingHelper.getTorque(
          this.maxDeflection,
          this.systemLength,
        );
      }
      return bearingTwistingAngle;
    },
  },
};
</script>

<style scoped lang="scss">
  .load-type {
    padding:20px;
    text-align: center;
    background: #fff;

    img {
      width:100%;
    }
  }

  @media (min-width: 1200px) {
    .load-type {
      img {
        width:400px;
      }
    }
  }
</style>
